<template>
  <div style="padding: 30px 0px; text-align: center"></div>
</template>
<script>
import { accessToken } from "../utils/apis/apis";
export default {
  name: "wxLogin",
  data() {
    return {};
  },
  created() {
    this.$reqGet(accessToken.getWechatUrl).then((res) => {
      if (res.data.code === 200) {
        window.location.replace(res.data.data);
      }
    });
  }
};
</script>